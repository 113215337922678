import React from "react";

function Footer() {
  return (
    <div>
      <footer className="bg-light py-5">
        <div className="container">
          <div className="small text-center text-muted">
            Copyright © <span>{new Date().getFullYear()}</span> - Akwaaba
            Evolution. All Rights Reserved
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
