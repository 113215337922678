import './App.css'
import './assets/css/styles.css'
import About from './components/About'
import Contact from './components/Contact'
import Header from './components/Header'
import MainNav from './components/MainNav'
import Portfolio from './components/Portfolio'
import Services from './components/Services'
import Footer from './components/Footer'
import Pricing from './components/Pricing'

function App() {
  return (
    <div className='App'>
      <MainNav />
      <Header />
      <About />
      <Services />
      <Portfolio />
      <Contact />
      {/* <Pricing /> */}
      <Footer />
    </div>
  )
}

export default App
