import React from 'react'
import uiux from '../assets/img/portfolio/thumbnails/ui-ux.jpg'
import school from '../assets/img/portfolio/thumbnails/2.jpg'
import apiApp from '../assets/img/portfolio/thumbnails/law.jpg'
import netfakes from '../assets/img/portfolio/thumbnails/netflix.jpg'
import chatApp from '../assets/img/portfolio/thumbnails/chat.png'
import npa from '../assets/img/portfolio/thumbnails/invoice.png'
import vien from '../assets/img/portfolio/thumbnails/dash.png'
import pharma from '../assets/img/portfolio/thumbnails/pharma.png'
import munchbow from '../assets/img/portfolio/thumbnails/about_1.jpg'
import naadei from '../assets/img/portfolio/thumbnails/decor.jpg'
import lunch from '../assets/img/portfolio/thumbnails/food.jpg'
import autoshop from '../assets/img/portfolio/thumbnails/homebg.jpg'
import todo from '../assets/img/portfolio/thumbnails/todo.PNG'
import space from '../assets/img/portfolio/thumbnails/spacetrips.PNG'
import tourism from '../assets/img/portfolio/thumbnails/tourism.png'
import pos from '../assets/img/portfolio/thumbnails/pos.jpg'

function Portfolio() {
  return (
    <div>
      <div id='portfolio'>
        <div className='container-fluid p-0'>
          <div className='row no-gutters'>
            <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box' target='_blank'
                href='https://github.com/akotonyStark/UI-UX'
              >
                <img className='img-fluid' src={uiux} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>UI-UX</div>
                  <div className='project-name'>UI-UX</div>
                </div>
              </a>
            </div>
            <div className='col-lg-4 col-sm-6'>
              <a className='portfolio-box' href='scholar'>
                <img className='img-fluid' src={school} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>
                    Educational
                  </div>
                  <div className='project-name'>Scholar (Php)</div>
                </div>
              </a>
            </div>
             <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://opokuamponsahandco.com/' target='_blank'
              >
                <img
                  className='img-fluid'
                  href='https://opokuamponsahandco.com/'
                  src={apiApp}
                  alt=''
                />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>Law Firm</div>
                  <div className='project-name'>
                    Opoku Amponsah and Co
                  </div>
                </div>
              </a>
            </div>
            <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://tinatett.netlify.app/' target='_blank'
              >
                <img className='img-fluid' src={pos} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>POS</div>
                  <div className='project-name'>Tinatett</div>
                </div>
              </a>
            </div>
            {/* <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='http://sleek-collections-api.herokuapp.com/api-docs/'
              >
                <img
                  className='img-fluid'
                  href='http://sleek-collections-api.herokuapp.com/api-docs/'
                  src={apiApp}
                  alt=''
                />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>APIs</div>
                  <div className='project-name'>
                    Sleek Collection API (NodeJs - Express)
                  </div>
                </div>
              </a>
            </div> */}
             <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://hopeful-perlman-632965.netlify.app' target='_blank'
              >
                <img className='img-fluid' src={pharma} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>ReactApp</div>
                  <div className='project-name'>Pharma</div>
                </div>
              </a>
            </div>

            <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://www.akwaabaevolution.com/munchbow/' target='_blank'
              >
                <img className='img-fluid' src={munchbow} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>
                    Food-commerce
                  </div>
                  <div className='project-name'>Rainbow Munchies</div>
                </div>
              </a>
            </div>
            <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://clever-fermi-0942b7.netlify.app/' target='_blank'
              >
                <img className='img-fluid' src={netfakes} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>ReactApp</div>
                  <div className='project-name'>Netfakes</div>
                </div>
              </a>
            </div>
            {/* <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://augustine-chat-app.herokuapp.com/'
              >
                <img className='img-fluid' src={chatApp} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>Chat App</div>
                  <div className='project-name'>React Chat App</div>
                </div>
              </a>
            </div> */}
            <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://vien-health-dashboard.netlify.app/' target='_blank'
              >
                <img className='img-fluid' src={vien} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>ReactApp</div>
                  <div className='project-name'>Dashboards</div>
                </div>
              </a>
            </div>
          
            {/* <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://npa-invoice-creator.netlify.app'
              >
                <img className='img-fluid' src={npa} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>
                    React App
                  </div>
                  <div className='project-name'> NPA Invoice Creator</div>
                </div>
              </a>
            </div> */}
            {/* <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://todo-app-webfx.netlify.app/'
              >
                <img className='img-fluid' src={todo} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>
                    React App
                  </div>
                  <div className='project-name'>ToDo App</div>
                </div>
              </a>
            </div> */}
            <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://space-trips.netlify.app/' target='_blank'
              >
                <img className='img-fluid' src={space} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>
                    React App with Mapbox
                  </div>
                  <div className='project-name'>Space Trips</div>
                </div>
              </a>
            </div>

            {/* <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://hopeful-perlman-632965.netlify.app'
              >
                <img className='img-fluid' src={tourism} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>
                    Tourism App
                  </div>
                  <div className='project-name'>SwitchupXperience</div>
                </div>
              </a>
            </div> */}

           
            {/* <div className='col-lg-4 col-sm-6'>
              <a
                className='portfolio-box'
                href='https://www.eventsbynaadei.com/'
              >
                <img className='img-fluid' src={naadei} alt='' />
                <div className='portfolio-box-caption'>
                  <div className='project-category text-white-50'>
                    Event Planning
                  </div>
                  <div className='project-name'>NaaDei Events</div>
                </div>
              </a>
            </div> */}
            <div className='col-lg-4 col-sm-6'>
              <a className='portfolio-box' href='https://plunch-1ad1e.web.app' target='_blank'>
                <img className='img-fluid' src={lunch} alt='' />
                <div className='portfolio-box-caption p-3'>
                  <div className='project-category text-white-50'>
                    Progressive Web App
                  </div>
                  <div className='project-name'>Lunch Ordering App (Open on Small Screen/Mobile device)</div>
                </div>
              </a>
            </div>
            <div className='col-lg-4 col-sm-6'>
              <a className='portfolio-box' href='autoshop'>
                <img className='img-fluid' src={autoshop} alt='' />
                <div className='portfolio-box-caption p-3'>
                  <div className='project-category text-white-50'>
                    Autoshop (username: Admin Password: Password)
                  </div>
                  <div className='project-name'>Autoshop (Php)</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portfolio
