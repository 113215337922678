import React from "react";

function Contact() {
  return (
    <div>
      <section className="page-section bg-dark text-white">
        <div className="container text-center">
          <h2 className="mb-4">Get your amazing applications from us!</h2>
          <a
            className="btn btn-light btn-xl"
            href="mailto:akwaabaevolution@gmail.com"
          >
            Contact Us Now!
          </a>
        </div>
      </section>

      <section className="page-section" id="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0">Let's Get In Touch!</h2>
              <hr className="divider my-4" />
              <p className="text-muted mb-5">
                Ready to start your next project with us? Give us a call or send
                us an email and we will get back to you as soon as possible!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
              <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
              <a className="d-block" href="mailto:wantwi28@gmail.com">
                Bill Antwi
              </a>
              <div>+233 24 198 2423</div>
            </div>
            <div className="col-lg-4 mr-auto text-center">
              <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>

              <a className="d-block" href="mailto:akwaabaevolution@gmail.com">
                akwaabaevolution@gmail.com
              </a>
            </div>
            <div className="col-lg-4 mr-auto text-center">
              <i className="fas fa-address-card fa-3x mb-3 text-muted"></i>

              <a className="d-block" href="mailto:joycelynyeboah30@gmail.com">
                Joycelyn Yeboah (Business Process Analyst)
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
